<template>
  <div class="helpContent" v-html="data.content"></div>
</template>

<script>
export default {
  name: "helpContent",
  components: {},
  directives: {},
  props: {
    data: {
      type: [Object, Array],
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.helpContent {
  text-align: left;
  width: 100%;
}
</style>
