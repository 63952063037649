<template>
  <el-container>
    <el-header height="120px">
      <p class="tableName" v-if="isList">
        <i class="el-icon-caret-right"></i>
        <span>常见问题</span>
        <span>列表</span>
      </p>
      <p class="tableName contentTableName" v-else>
        <i class="el-icon-caret-right"></i>
        <span>{{ contentData.title }}</span>

        <el-button @click="goList" class="goList">返回</el-button>
      </p>
    </el-header>
    <el-main v-if="isList">
      <ul>
        <li v-for="(item, index) in list.data" :key="index" @click="goContent(item)">
          {{ item.title }}
        </li>
      </ul>
      <el-pagination
        v-if="list.data"
        @current-change="handleCurrentChange"
        :current-page="0"
        :page-size="5"
        layout="prev, pager, next"
        :total="list.total"
      ></el-pagination>
    </el-main>
    <el-main v-else>
      <div class="helpContent">
        <help-content :data="contentData"></help-content>
      </div>
    </el-main>
  </el-container>
</template>
<script>
// import useing from "./content/use";
// import useparents from "./content/useParents";
import helpContent from "./content/content";
import http from "@/http/api";
export default {
  name: "",
  props: {},
  components: { helpContent },
  data() {
    return {
      list: {
        data: [],
        total: null,
        pageNumber: 1,
        size: 5,
      },
      contentData: null,
      isList: true,
    };
  },
  created() {},
  mounted() {
    this.getHelpList();
  },
  methods: {
    handleCurrentChange(val) {
      this.list.pageNumber = val;
      // console.log(`当前页: ${val}`);
      this.getHelpList();
    },
    async goContent(val) {
      const data = await http.getHelp(val.id);
      console.log(data);
      if (data.status == 200) {
        this.contentData = data.data;
        this.isList = false;
      } else {
        this.$message({
          message: "获取详情失败,请稍后再试",
          type: "error",
        });
      }
    },
    goList(id) {
      this.isList = true;
    },
    // 获取文章列表
    async getHelpList() {
      const data = await http.getHelpList("SCHOOL_REPORT", "SR_HELP_TEACHER", {
        pageNumber: this.list.pageNumber - 1,
        size: this.list.size,
      });
      console.log(data.headers["x-total-count"]);
      if (data.status == 200) {
        this.list.data = data.data;
        this.list.total = +data.headers["x-total-count"];
      } else {
        this.$message({
          message: "获取文章列表失败,请稍后再试!",
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-container {
  height: calc(100vh - 80px);
  overflow: hidden;
  background-color: #fff;
  .el-header {
    text-align: left;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.2);

    .tableName {
      height: 100%;
      line-height: 120px;
      padding-left: 44px;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #eb6123;
      i {
        width: 20px;
        height: 20px;
        transform: scale3d(1.8, 1.2, 1);
        margin: 0 20px 0 10px;
        text-shadow: 2px 0 3px #eb6123;
      }
    }
    .contentTableName {
      .goList {
        float: right;
        font-size: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #eb6123;
        margin: 40px 100px 0 0;
      }
    }
  }
  .el-main {
    background-color: #fff;
    max-height: calc(100vh - 200px);
    position: relative;
    min-width: 300px;
    ul {
      width: 740px;
      padding-left: 60px;
      li {
        text-align: left;
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid #eb6123;
        cursor: pointer;
      }
    }
    ::v-deep.el-pagination {
      position: absolute;
      left: 50%;
      bottom: 168px;
      transform: translateX(-50%);
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      .el-pager {
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
      }
      .el-icon {
        color: #eb6123;
        font-size: 16px;
      }
      .number.active {
        color: #eb6123;
        font-weight: 700;
      }
    }
  }
}
.goList {
  color: #eb6123;
  font-size: 18px;
  font-weight: 600;
  border-radius: 6px;
  border: 0;
  bottom: 20px;

  &:hover {
    border: 1px solid #eb6123;
    box-shadow: 0px 0px 5px 3px rgba($color: #eb6123, $alpha: 0.2);
    background: unset;
  }
}
.helpContent {
  width: 1440px;
  margin: 0 auto;
}
</style>
